<template>
  <section>
    <Portal to="headerActions">
      <b-dropdown aria-role="list">
        <template #trigger="{ active }">
          <b-button
            :icon-right="active ? 'menu-up' : 'menu-down'"
            :label="$t('global.reports')"
            :loading="downloadInProgress"
            type="is-primary"
            v-if="hasPermissionDonwloadCSV"
          />
        </template>
        <DownloadEOD
          :downloadInProgress.sync="downloadInProgress"
          :newDate="date"
          aria-role="listitem"
        >
          <b-dropdown-item>
            {{ $t('dispatch.downloadEOD') }}
          </b-dropdown-item>
        </DownloadEOD>
        <b-dropdown-item aria-role="listitem" @click="() => (isModalOpen.downloadModal = true)">
          {{ $t('global.downloadCopaymentReport') }}
        </b-dropdown-item>
      </b-dropdown>
      <Link to="/dispatch/issues" isRouter>
        <Button
          class="ml-3"
          type="is-primary"
          :label="$t('dispatch.deliveryIssues')"
          icon-left="alert-outline"
          :permission="permission.viewIssues"
        />
      </Link>
      <Link to="/change-log/delivery_manifest" isRouter>
        <Button
          class="ml-3"
          type="is-primary"
          :label="$t('dispatch.changeLog')"
          icon-left="note-text"
          :permission="permissionActivities.readAll"
        />
      </Link>
    </Portal>
    <DownloadModal :isOpen.sync="isModalOpen.downloadModal" />
    <LunchBreak :isOpen.sync="isModalOpen.lunchBreak" />
    <HistoricSearchModal :isOpen.sync="isModalOpen.historySearcher" :date="mainParams.date" />
    <Card id="dispatch">
      <div class="flex">
        <div id="dispatch-table-side">
          <div id="tableHeader" class="flex f-jce">
            <div class="flex">
              <!-- <LateDeliveriesCard :quantity="13" /> -->
              <Button
                v-if="!isToday"
                class="mr-1"
                type="is-primary"
                :tooltip="$t('dispatch.searchHReport')"
                icon-left="clipboard-text-search"
                :permission="permission.deliveryEvents"
                @click="isModalOpen.historySearcher = true"
              />
              <div class="flex f-jce">
                <Input-search
                  icon="magnify"
                  :placeholder="activeTab == 0 ? $t('run.search') : $t('schedule.search.title')"
                  v-model="mainParams.all"
                  :disabled="isUnsuccessful"
                />
                <DatePicker
                  v-if="hasPermission"
                  class="datepicker ml-3"
                  v-model="mainParams.date"
                  :maxDate="maxDate"
                  icon-right="calendar-today"
                  :disabled="loading || isUnsuccessful"
                />
              </div>
            </div>
          </div>
          <b-tabs v-model="activeTab" :animated="false">
            <b-tab-item :label="$t('dispatch.table.title')">
              <MainTable
                ref="MainTable"
                :loading.sync="loading"
                :isToday="isToday"
                :params="params"
                @select="onSelectDriver"
              />
            </b-tab-item>
            <b-tab-item v-if="hasSchedulingPermission" :disabled="!isDriverSelected || disabled">
              <template #header>
                <span>
                  {{ $t('schedule.mainTableTitle') }}
                  <template v-if="totalItems.unassigned">
                    {{ totalItemsUnassigned }}
                  </template>
                </span>
              </template>
              <ScheduleTable
                @setTotalItems="handleTotalItemsUnassigned"
                ref="ScheduleTable"
                :params="schParams"
                :disabled="disabled"
                dispatchView
              />
            </b-tab-item>
            <b-tab-item v-if="hasSchedulingPermission">
              <template #header>
                <span>
                  {{ $t('schedule.table.unsuccessful') }}
                  <template v-if="totalItems.unsuccessful">
                    {{ totalItemsUnsuccessful }}
                  </template>
                </span>
              </template>
              <UnsuccessfulTable
                @setTotalItems="handleTotalItemsUnsuccessful"
                :params="unParams"
                :disabled="disabled"
                dispatchView
              />
            </b-tab-item>
          </b-tabs>
        </div>
        <div id="dispatch-manifest-side">
          <Manifest
            ref="Manifest"
            :disabled="disabled"
            :date="date"
            :isLoading="!isDriverSelected && loading"
          >
            <template #header> </template>
          </Manifest>
        </div>
      </div>
    </Card>
  </section>
</template>

<script>
import { LunchBreak } from '@/components/Dispatch/Modals';
import { Card, DatePicker, DownloadEOD, InputSearch, Link, Button } from '@/components';
import { HistoricSearchModal } from '@/views/Fragments';
import MainTable from '@/components/Dispatch/MainTable.vue';
import Manifest from '@/components/Dispatch/Manifest.vue';
import ScheduleTable from '@/components/Schedule/MainTable.vue';
import { Permissions } from '@/utils/Secure';
import UnsuccessfulTable from '@/views/Fragments/UnsuccessfulTable';
import DownloadModal from './Modals/DownloadReportModal.vue';

export default {
  components: {
    Button,
    Card,
    DatePicker,
    DownloadEOD,
    DownloadModal,
    HistoricSearchModal,
    InputSearch,
    Link,
    LunchBreak,
    MainTable,
    Manifest,
    ScheduleTable,
    UnsuccessfulTable
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { schedule: { lunchBreak } }) => {
      if (type == 'schedule/lunchBreak') {
        this.lunchBreak.disabledButton = !!lunchBreak;
      }
    });
  },
  mounted() {
    this.updateMapBreadcumbs();
    this.updateMapWindow();
    this.handleAutoUpdate();
  },
  destroyed() {
    this.updateMapBreadcumbs(true);
    this.unsubscribe();
    this.cancelAutoUpdate();
  },
  data() {
    return {
      activeTab: 0,
      date: this.$moment().format('YYYY-MM-DD'),
      disabled: false,
      loading: true,
      isDriverSelected: false,
      isModalOpen: {
        shareRx: false,
        lunchBreak: false,
        cancelDelivery: false,
        historySearcher: false,
        downloadModal: false
      },
      mainParams: { date: this.$moment().format('YYYY-MM-DD'), all: null },
      params: { date: this.$moment().format('YYYY-MM-DD'), all: null },
      schParams: { delivery_date: this.$moment().format('YYYY-MM-DD'), all: null },
      unParams: { delivery_date: this.$moment().format('YYYY-MM-DD'), all: null },
      unsubscribe: null,
      timeout: { autoUpdate: 0 },
      totalItems: {
        unassigned: null,
        unsuccessful: null
      },
      downloadInProgress: false
    };
  },
  computed: {
    isToday() {
      return !this.params.date || this.params.date == this.$moment().format('YYYY-MM-DD');
    },
    maxDate() {
      return this.$moment().toDate();
    },
    hasPermission() {
      return this.Secure.permissionValidator(Permissions.Dispatch.readAll);
    },
    hasSchedulingPermission() {
      return this.Secure.permissionValidator(Permissions.Scheduling.readAll);
    },
    permission() {
      return Permissions.Dispatch;
    },
    permissionActivities() {
      return Permissions.ActivityLogger;
    },
    totalItemsUnassigned() {
      return `(${this.totalItems.unassigned})`;
    },
    totalItemsUnsuccessful() {
      return `(${this.totalItems.unsuccessful})`;
    },
    isUnsuccessful() {
      return this.activeTab === 2;
    },
    hasPermissionDonwloadCSV() {
      return this.Secure.permissionValidator(Permissions.Dispatch.downloadCSV);
    }
  },
  methods: {
    handleTotalItemsUnassigned(total) {
      this.totalItems.unassigned = total;
    },
    handleTotalItemsUnsuccessful(total) {
      this.totalItems.unsuccessful = total;
    },
    handleAutoUpdate() {
      this.timeout.autoUpdate = setTimeout(() => {
        this.handleUpdateData();
        this.handleAutoUpdate();
      }, 60000);
    },
    handleUpdateData() {
      this.$refs.MainTable.handleUpdate();
      this.$refs.Manifest.getManifest(true);
    },
    cancelAutoUpdate() {
      this.handleCancelRequests();
      clearTimeout(this.timeout.autoUpdate);
    },
    handleCancelRequests() {
      this.$refs.MainTable?.cancelRequest();
      this.$refs.Manifest?.cancelRequest();
    },
    onSelectDriver({ id, delivery_manifest_status_id }) {
      this.isDriverSelected = id ? true : false;
      this.disabled = delivery_manifest_status_id !== 3 || !this.isToday;
    },
    updateMapWindow() {
      this.$store.dispatch('map/updateMapWindow', {
        action: 'showDispatch',
        view: 'dispatch',
        data: []
      });
    },
    updateMapBreadcumbs(clear = false) {
      this.$store.commit('map/breadcumbs', clear ? null : [this.$t('menu.dis')]);
    }
  },
  watch: {
    activeTab(tab) {
      if (tab) {
        this.mainParams.all = this.schParams.all;
        this.mainParams.date = this.schParams.delivery_date;
      } else {
        this.mainParams.all = this.params.all;
        this.mainParams.date = this.params.date;
      }
    },
    isToday(value) {
      if (value) {
        this.handleAutoUpdate();
      } else {
        this.cancelAutoUpdate();
      }
    },
    'mainParams.all'(value) {
      this.handleCancelRequests();
      this.activeTab ? (this.schParams.all = value) : (this.params.all = value);
    },
    'mainParams.date'(date) {
      if (date !== 'Invalid date') {
        this.handleCancelRequests();
        this.disabled = date < this.$moment().format('YYYY-MM-DD');
        this.activeTab ? (this.schParams.delivery_date = date) : (this.params.date = date);
        this.date = date;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
section
  height: calc(100% - 100px)
#dispatch
  margin: 0
  height: 100%
  #dispatch-table-side
    width: 100%
    margin-top: 20px
    margin-right: 15px
    #unattended-deliveries-table
      margin-top: 0
    @media screen and ( min-width: $bp-xl )
      #tableHeader
        margin-bottom: -35px
  #dispatch-manifest-side
    width: 450px
  ::v-deep
    .card-content,
    .card-content > .flex
      height: 100%
      .b-tabs
        height: 100%
      .tab-content
        height: calc(100% - 30px)
        padding: 1em 0px
      .tab-item
        height: 100%
    .datepicker
      margin-bottom: 0
    #dispatch-manifest-side #manifest-header-slot
      margin-bottom: .50rem
    #dispatch-table-side
      .tabs li
        font-weight: bold
        &:not(.is-active) a
          color: $gray-400
          border-bottom-color: $gray-400
</style>
