<template>
  <Modal
    :buttonLabel="$t('global.download')"
    :header="$t('global.downloadCopaymentReport')"
    :isOpen.sync="isModalActive"
    :loading="isLoading"
    :saveDisabled="isLoading.save || !date"
    @cancel="() => {}"
    @save="onDownloadReport"
    id="DownloadReportModal"
    size="is-small"
  >
    <div class="container-select">
      <label>{{ $t('global.selectPeriod') }}</label>
      <div class="container-buttons">
        <Button
          :label="$t('global.month')"
          :outlined="!isMonth"
          @click="() => handleDatePickerType('MONTH')"
          type="is-primary"
        />
        <Button
          :label="$t('button.day')"
          :outlined="isMonth"
          @click="() => handleDatePickerType('DAY')"
          type="is-primary"
        />
      </div>
      <b-datepicker
        :max-date="maxDateComputed"
        class="datepicker"
        inline
        size="is-small"
        v-bind="propsToPass"
        v-model="date"
        :locale="locale"
      />
    </div>
  </Modal>
</template>

<script>
import { Modal, Button } from '@/components';
import { toast } from '@/utils/dialog';

export default {
  components: {
    Modal,
    Button
  },
  data() {
    return {
      date: this.$moment().toDate(),
      isLoading: { save: false },
      isModalActive: false,
      isMonth: false
    };
  },
  computed: {
    maxDateComputed() {
      return this.$moment(this.maxDate).toDate();
    },
    propsToPass() {
      return this.isMonth ? { type: 'month' } : {};
    },
    locale() {
      return this.$store.getters.LANG === 'es' ? 'es-ES' : 'en-US';
    }
  },
  methods: {
    handleDatePickerType(type) {
      this.isMonth = type === 'MONTH';
    },
    onInput(date) {
      this.date = date;
    },
    async onDownloadReport() {
      this.isLoading.save = true;

      try {
        const response = await this.Api.get(
          `/delivery_manifest/reports/copayment/csv?${
            this.isMonth
              ? `month=${this.$moment(this.date).format('MM')}`
              : `date=${this.$moment(this.date).format('YYYY-MM-DD')}`
          }`
        );

        if (!response) toast('warning', this.$t('errors.noInformation'), 10000);
        else {
          toast('success', this.$t('notification.requestSuccessfully'), 10000);
          this.isModalActive = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading.save = false;
      }
    },
    clearData() {
      this.date = this.$moment().toDate();
      this.isMonth = false;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalActive = value;
      if (!value) this.clearData();
    },
    isModalActive(value) {
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    value: { type: Object, default: () => {} },
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#DownloadReportModal
  .container-select
    margin: 0 1.2rem
  label
    font-size: 18px
    width: 100%
    font-weight: 800
  ::v-deep .animation-content .card .h-100 .modal-content-container
    height: 540px
    .slot
      padding-bottom: 0 !important
    .modal-footer
      justify-content: space-around
      width: 100%
  .datepicker
    margin-top: 0.8rem
    box-shadow: 0px 2px 12px rgba(24, 56, 153, 0.12)
    ::v-deep .dropdown-menu
      display: block
      .dropdown-content
        box-shadow: none
        .dropdown-item
          box-shadow: none
  .container-buttons
    display: flex
    width: 100%
    justify-content: center
    margin-top: 12px
    .button
      margin: 0 !important
      width: 50%
      ::v-deep
        margin: 0
    .button:nth-child(1)
      border-radius: 5px 0 0 5px
    .button:nth-child(2)
      border-radius: 0 5px 5px 0
</style>
