<template>
  <div id="table" class="flex f-column">
    <EditRun :isOpen.sync="modals.isEditRunOpen" :value="selected" />
    <Table
      v-if="apiUrl"
      :loading.sync="oLoading.table"
      :perPage="15"
      :show-detail-icon="false"
      :params="aParams"
      :apiUrl="apiUrl"
      class="dispatch-table"
      infiniteScroll
      @select="onSelect"
      @click="onClick"
      @loadedItems="onLoadItems"
      :selected="selected"
      ref="Table"
    >
      <div id="manifest-status-symbology" class="flex">
        <div
          v-for="(status, i) in manifestStatus"
          :key="'manifest-status-' + i"
          class="flex f-ai mr-4"
        >
          <ManifestStatusSignUI :id="status.id" iconMode>
            <p>{{ status.name }}</p>
          </ManifestStatusSignUI>
        </div>
      </div>
      <template #empty>
        <Empty :title="$t('dispatch.table.empty.title')" :text="$t('dispatch.table.empty.text')" />
      </template>
      <b-table-column :label="$t('runs.label')" v-slot="props" :td-attrs="tdAttrs">
        <div class="flex f-ai">
          <ManifestStatusSignUI
            class="driver-name"
            :id="props.row.delivery_manifest_status_id"
            iconMode
          >
            <p class="ml-2">{{ runNameMaker(props.row) }}</p>
          </ManifestStatusSignUI>
        </div>
      </b-table-column>
      <b-table-column :label="$t('global.driver')" v-slot="props" :td-attrs="tdAttrs">
        <div>{{ props.row.employee_name }}</div>
      </b-table-column>
      <b-table-column :label="$t('run.vehicle')" v-slot="props" :td-attrs="tdAttrs">
        <div>{{ props.row.vehicle_name }}</div>
      </b-table-column>
      <b-table-column :label="$t('global.lastNextEvent')" v-slot="props" :td-attrs="tdAttrs">
        {{ hasEvents(props.row.events, 0, 'dispatch.dropoffReturn') }}
        <br />
        {{ hasEvents(props.row.events, 1, 'dispatch.pickupReturn') }}
      </b-table-column>
      <b-table-column :label="$t('trips.title')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.delivery_count || '-' }}
      </b-table-column>
      <b-table-column v-slot="props" width="70" :td-attrs="tdAttrs">
        <div class="flex">
          <Link
            v-if="!disabled"
            class="btn-edit"
            type="is-primary"
            :label="$t('run.edit')"
            @click="openModal('isEditRunOpen')"
            icon="pencil"
            :loading="oLoading.rowId == props.row.id"
            :permission="Permission.update"
            :disabled="disabled || props.row.delivery_manifest_status_id > 3"
          />
        </div>
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import { Link, Table, Empty, ManifestStatusSignUI } from '@/components';
import { EditRun } from '@/components/Dispatch/Modals';
// import LateDeliveriesCard from '@/components/Dispatch/LateDeliveriesCard';
//import { toast } from '@/utils/dialog';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    EditRun,
    // LateDeliveriesCard,
    Link,
    Table,
    Empty,
    ManifestStatusSignUI
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { dispatch }) => {
      if (type === 'dispatch/manifest') this.manifest = dispatch?.manifest;

      if (type === 'dispatch/reloadTable') this.handleUpdate();
    });
  },
  destroyed() {
    this.$store.dispatch('map/manifestId', null);
  },
  mounted() {
    this.getParams();
    this.disabled = this.isToday ? false : true;
  },

  data() {
    return {
      aParams: [],
      disabled: false,
      oLoading: { save: false, table: this.loading, rowId: 0 },
      search: '',
      modals: { isEditRunOpen: false },
      manifest: {},
      selected: {}
    };
  },
  computed: {
    apiUrl() {
      return this.isToday ? 'delivery_manifest/current_deliveries_manifest' : 'delivery_manifest';
    },
    bTable() {
      return this.$refs?.Table?.bTable;
    },
    manifestStatus() {
      return [
        { id: 2, name: this.$t('manifest.status.scheduled') },
        { id: 3, name: this.$t('manifest.status.running') },
        { id: 4, name: this.$t('manifest.status.done') },
        { id: 5, name: this.$t('manifest.status.incomplete') }
      ];
    },
    Permission() {
      return Permissions.Runs;
    }
  },
  methods: {
    runNameMaker({ employee_name, vehicle_name }) {
      const splittedName = employee_name.split(',');
      const firstName = splittedName[1];
      const lastName = splittedName[0];
      const name = firstName || lastName;
      return `${name} - ${vehicle_name}`;
    },
    hasEvents(events, index, label) {
      let value = '-';
      if (events && events.length > 0) {
        if (events[index]) value = `${events[index].destination_full_address} - ${this.$t(label)}`;
      }
      return value;
    },
    async handleUpdate() {
      await this.$refs.Table.updateDataTable();
    },
    async cancelRequest() {
      this.$refs.Table.cancelRequest();
    },

    async getData() {
      await this.$refs.Table.getData({ clear: true });
    },
    getParams() {
      this.aParams = [];
      Object.keys(this.params).map((key) => {
        const value = this.params[key];
        if (key == 'date' && this.isToday) return;
        if (value && value != 'all') this.aParams.push(`${key}=${value}`);
      });
      if (!this.isToday) this.aParams = [...this.aParams, `avoid_created_items=1`];
    },
    onClick(delivery) {
      this.$store.dispatch('map/manifestId', delivery.id);
    },
    onLoadItems(items = []) {
      const firstActiveRun = items.filter((i) => i.delivery_manifest_status_id === 3)[0];
      const selected = firstActiveRun || items[0];
      if (!this.selected.id && selected) {
        this.$refs.Table.selected = selected;
        this.onClick(selected);
        this.onSelect(selected);
      }
    },
    onSelect(value) {
      if (value.id === this.selected.id) return;
      this.selected = value;
      this.$emit('select', value);
      this.$store.commit('dispatch/run', value);
    },
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      if (row.delivery_manifest_status_id > 3 || this.disabled) classes.push('disabled');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    },
    toggleDetails(row) {
      row.isOpen = !row.isOpen;
      this.bTable.toggleDetails(row);
    },
    openModal(modalName) {
      this.modals[modalName] = true;
    }
  },
  watch: {
    isToday(value) {
      this.disabled = value ? false : true;
    },
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    },
    params: {
      handler(value) {
        if (value) this.getParams();
      },
      deep: true
    }
  },
  props: {
    isToday: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    params: { type: Object, default: () => {} }
  }
};
</script>
<style lang="sass" scoped>
#table
  border-radius: $br-md
  height: 100%
.dispatch-table
  #manifest-status-symbology
    font-size: 14px
    margin-bottom: 16px
    .iconManifestStatusUI p
      color: $blue-700
  ::v-deep
    .is-selected
      cursor: default
      .btn-edit
          display: block
          color: $blue-500
    #manifest-status-symbology .statusIcon .mdi:before
      font-size: 16px
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
      &:hover
        .btn-edit
          display: block
    .table-wrapper
      height: calc(100vh - 265px)
  .btn-edit
    display: none
</style>
<style lang="sass">
.dark
  .table tr
    .btn-edit a
      color: $main-background !important
  .table tr .disabled
    opacity: 1 !important
    .btn-edit
      color: $gray-500 !important
  .table tr.is-selected .disabled
    background-color: $blue-500 !important
  #manifest-status-symbology
    p
      color: $main-background !important
  .driver-name
    @each $i,$color in $manifestStatusFontColorsDark
      &.#{$i}
        color: $color
    &.running
      color: $blue-200 !important
</style>
