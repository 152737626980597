var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{ref:"table",staticClass:"delivery-issues-table",attrs:{"focusable":false,"loading":_vm.oLoading.table,"params":_vm.aParams,"show-detail-icon":false,"apiUrl":"delivery_manifest/unsuccesfully_events","infiniteScroll":""},on:{"update:loading":function($event){return _vm.$set(_vm.oLoading, "table", $event)}}},[_c('b-table-column',{attrs:{"label":_vm.$t('global.order'),"width":"170","td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"flex"},[_c('DeliveryIcons',{attrs:{"isTag":"","status":{
            collect: props.row.delivery_event_type_id === 8,
            leaveAtDoor: props.row.leave_at_door,
            priority: props.row.priority,
            refrigerated: props.row.refrigeration_needed,
            rush: props.row.rush,
            male_driver: props.row.male_driver,
            female_driver: props.row.female_driver
          }}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('global.date'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('DateTime',{attrs:{"date":props.row.delivery_date,"format":"MM/DD/Y"}}),_c('ConstraintDatesViewer',{staticClass:"text-wrap",attrs:{"isTag":"","endTime":props.row.delivery_end_time,"offset":props.row.time_offset,"startTime":props.row.delivery_start_time}})]}}])}),_c('b-table-column',{attrs:{"label":_vm.$tc('participant.label'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',[_vm._v(" "+_vm._s(props.row.participant_full_name)+" ")]),_vm._v(" "+_vm._s(props.row.client_code)+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('schedule.delivery'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p',[_vm._v(_vm._s(props.row.destination_full_address || '-'))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('menu.cdp'))+": "),_c('span',[_vm._v(_vm._s(props.row.cdp_full_address || '-'))])])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('employee.name.driver'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',[_vm._v(" "+_vm._s(props.row.employee_full_name)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('dispatch.issue'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [([11, 7, 6].includes(props.row.delivery_event_status_id))?_c('p',[_vm._v(" "+_vm._s(_vm.$t('dispatch.notReturned'))+" ")]):_c('p',[_vm._v(_vm._s(_vm.$t('dispatch.notDelivered')))])]}}])}),_c('b-table-column',{attrs:{"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"flex"},[(_vm.hasPermissionFix)?_c('Link',{staticClass:"ml-5 mr-5",attrs:{"icon":"tools","label":_vm.$t('global.fix')},on:{"click":function($event){return _vm.onDeliveryFix(props.row)}}}):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }