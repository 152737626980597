var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"unsuccessful-deliveries-table"}},[_c('Deliveries',{attrs:{"isOpen":_vm.showDeliveryModal,"delivery":_vm.editRowData},on:{"update:isOpen":function($event){_vm.showDeliveryModal=$event},"update:is-open":function($event){_vm.showDeliveryModal=$event},"save":_vm.getData}}),_c('Table',{ref:"table",staticClass:"unsuccessful-table",attrs:{"dataPreProcessor":_vm.deliveriesDataPreProcessor,"focusable":false,"loading":_vm.oLoading.table,"perPage":40,"show-detail-icon":false,"apiUrl":"delivery_manifest/unsuccessful_deliveries","infiniteScroll":"","updateInterval":70},on:{"update:loading":function($event){return _vm.$set(_vm.oLoading, "table", $event)},"setTotalItems":function (data) { return _vm.$emit('setTotalItems', data); }}},[_c('div',{staticClass:"flex f-jsb f-ai options-header"},[_c('div',{staticClass:"flex icons"},[_c('DeliveryStatusUI',{attrs:{"type":"rush","component":"simple-text"}}),_c('DeliveryStatusUI',{attrs:{"type":"priority","component":"simple-text"}}),_c('DeliveryStatusUI',{attrs:{"type":"refrigerated","component":"simple-text"}}),_c('DeliveryStatusUI',{attrs:{"type":"leave-at-door","component":"simple-text"}}),_c('DeliveryStatusUI',{attrs:{"type":"collect","component":"simple-text"}}),_c('DeliveryStatusUI',{attrs:{"type":"male-driver","component":"simple-text"}}),_c('DeliveryStatusUI',{attrs:{"type":"female-driver","component":"simple-text"}})],1)]),_c('b-table-column',{attrs:{"label":_vm.$t('global.order'),"width":"240","td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"flex f-wrap cell-order icons-table is-align-items-center"},[_c('DeliveryIcons',{attrs:{"status":{
            rush: props.row.rush,
            priority: props.row.priority,
            refrigerated: props.row.refrigeration_needed,
            leaveAtDoor: props.row.leave_at_door,
            collect: props.row.is_collect_event,
            male_driver: props.row.male_driver,
            female_driver: props.row.female_driver
          }}}),_c('span',[_vm._v(" "+_vm._s(props.row.order_number)+" ")]),_c('br'),_c('CopaymentTag',{attrs:{"value":props.row.copayment_amount}}),_c('div',{staticClass:"failed-status"},[_c('span',[_vm._v(" "+_vm._s(_vm.failedStatus(props.row.delivery_event_status_id)))])])],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('global.date'),"width":"130","td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('DateTime',{staticClass:"f-jc",attrs:{"date":props.row.delivery_date,"format":"MM/DD/Y"}}),_c('ConstraintDatesViewer',{attrs:{"isTag":"","offset":props.row.offset_tz,"startTime":props.row.delivery_start_time,"endTime":props.row.delivery_end_time}})]}}])}),_c('b-table-column',{attrs:{"label":_vm.$tc('participant.label'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.participant_full_name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('schedule.delivery'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"deliveryAddress"},[_c('strong',[_vm._v(_vm._s(props.row.participant_full_address || '-'))]),_c('p',[_vm._v(_vm._s(props.row.cdp_full_address || '-'))])])]}}])}),_c('b-table-column',{attrs:{"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"flex"},[_c('Link',{staticClass:"ml-auto",attrs:{"label":_vm.$t('button.showMap'),"icon":"map-marker-radius","permission":_vm.Permission.showOnMap,"autoLoading":""},on:{"click":function () { return _vm.onShowMarkerOnMap(props.row); }}}),_c('Link',{staticClass:"dropdown",attrs:{"label":_vm.$t('schedule.assignDelivery'),"icon":"arrow-right-bold-box","disabled":_vm.oDisabled ||
            !_vm.manifest ||
            !_vm.manifest.id ||
            _vm.oLoading.rowId != 0 ||
            _vm.oLoading.delete ||
            props.row.canceled,"loading":_vm.oLoading.rowId == props.row.id,"permission":_vm.Permission.assign},on:{"click":function () { return _vm.onAssignDelivery(props.row); }}}),(_vm.moreActionsDropdown.length > 0)?_c('Dropdown',{attrs:{"label":_vm.$t('global.moreActions'),"maxHeight":200,"loading":props.row.id == _vm.cancelingId,"position":"is-bottom-left","icon":"dots-horizontal","showLabel":false,"disabled":_vm.oLoading.rowId != 0 || _vm.oLoading.delete,"items":_vm.moreActionsDropdown(props.row.canceled)},on:{"click":function (value) { return _vm.onClick(value, props.row); }}}):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }