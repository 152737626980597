<template>
  <div id="manifest" :class="isEmpty ? 'is-empty' : ''">
    <BypassModal :isOpen.sync="modals.isBypass" :employeeId="manifest.employee_id" />
    <Deliveries :isOpen.sync="modals.isAddOnOpen" :manifestId="manifestId" isAddOn />
    <CancelDelivery :isOpen.sync="modals.isCancelDeliveryOpen" :eventId="eventId" />
    <ChangesHistory :isOpen.sync="modals.isChangesHistoryOpen" :eventId="eventId" />
    <ModalCopayment
      :isOpen.sync="modals.isEditCopayment"
      :delivery="deliveryEvent"
      @saved="handleSavedCopayment"
    />
    <ChangeStatus
      :isOpen.sync="modals.isChangeStatus"
      :eventId="eventId"
      :delivery="deliveryEvent"
    />
    <LunchBreak :isOpen.sync="modals.isLunchBreakOpen" />
    <UnasignDeliveryModal
      :isOpen.sync="modals.isUnassignOpen"
      :delivery="currentDelivery"
      @executeDesignation="onRemoved"
    />
    <ModalEditParticipant
      :isOpen.sync="modals.isEditParticipant"
      :delivery="participandDelivery"
      @updateEvents="getManifest"
    />
    <TransferRx
      :isOpen.sync="modals.isTransferRxOpen"
      :eventId="eventId"
      :manifestId="manifest.id"
      :from="manifest.employee_id"
      @transfered="onTransferRx"
    />
    <ShareRx
      :isOpen.sync="modals.isShareRxOpen"
      :deliveryId="eventId"
      :trackingToken="trackingToken"
      :delivery="deliveryEvent"
    />
    <div v-if="isLoading" class="loadingManifests">
      <b-skeleton height="100%" />
    </div>
    <Empty
      v-else-if="isEmpty"
      :title="$t('dispatch.manifest.empty.title')"
      :text="$t('dispatch.manifest.empty.text')"
    />
    <template v-else>
      <div id="manifest-header" class="title-container">
        <b-skeleton v-if="loading.manifest" width="100%" height="30" />
        <div v-else class="title">{{ manifest.employee_name }}</div>
        <div class="flex f-jsb f-ai f-wrap mt-1">
          <ManifestStatusSignUI
            :id="manifest.delivery_manifest_status_id"
            class="mr-1"
            :skeletonLoading="loading.manifest"
          />
          <div v-if="loading.manifest">
            <b-skeleton width="150" />
          </div>
          <div v-else class="flex f-column f-jc">
            <p class="text has-text-right">
              {{ $t('schedule.manifest.estimatedDistance') }}: {{ distance }} mi
            </p>
            <p v-if="workingHours > 8" class="help is-danger has-text-weight-bold has-text-right">
              {{ $t('schedule.manifest.exceedsWorkingHours') }}
            </p>
          </div>
        </div>
      </div>
      <!-- v-if="!loading.manifest" -->
      <div id="manifest-header-slot">
        <div class="flex mt-2">
          <Button
            class="button-manifest mr-2 w-100"
            :label="$t('dispatch.addOn')"
            @click="isAddOnOpen"
            :disabled="disabled"
            outlined
            type="is-primary"
            :permission="Permission.addOn"
            :skeletonLoading="loading.manifest"
          />
          <Button
            :permission="PermissionBypass.bypass"
            :skeletonLoading="loading.manifest"
            :tooltip="$t('dispatch.bypassToolTip')"
            :disabled="disabled && disabledBypass"
            @click="isBypassOpen"
            icon-right="debug-step-over"
            outlined
            type="is-primary"
          />
        </div>
        <div class="flex mt-2 mb-2">
          <!-- <Button
            :label="$t('schedule.addLunch')"
            :disabled="disabled"
            @click="modals.isLunchBreakOpen = true"
            outlined
            class="button-manifest"
            type="is-primary"
            :permission="Permission.addOn"
            :skeletonLoading="loading.manifest"
          /> -->
          <RecalculateManifestRoute
            class="w-100"
            :disabled="disabled"
            :manifest="manifest"
            :skeletonLoading="loading.manifest"
            outlined
            expanded
            @update="
              (result) => {
                manifest.is_route_updated = result;
              }
            "
          />
          <Button
            :tooltip="$t('dispatch.notifyDriver')"
            :disabled="disabled"
            icon-right="bullhorn"
            class="ml-2"
            @click="notificateToApp"
            outlined
            :loading="loading.notifyDriver"
            type="is-primary"
            :permission="Permission.notifyDriver"
            :skeletonLoading="loading.manifest"
          />
          <CDPFilter
            class="ml-2"
            :skeletonLoading="loading.manifest"
            @loaded="setCDPs"
            v-model="CDPsFilter"
            :filterIds="CDPsInManifest"
          />
        </div>
        <Input-search
          icon="magnify"
          :placeholder="$t('schedule.search.title')"
          v-model="search"
          :skeletonLoading="loading.manifest"
        />
        <slot name="header" />
      </div>
      <div id="manifest-container">
        <div v-if="loading.manifest" id="manifest-loading">
          <b-skeleton height="100%" />
        </div>
        <template v-else>
          <div v-if="loading.manifest">
            <b-skeleton v-for="i in 10" :key="i" height="60" />
          </div>
          <template v-else>
            <!-- <template v-for="item in deliveryEvents">
              <DeliveryEvent
                v-if="item.delivery_event_type_id < 3 && !search"
                :key="item.id"
                :delivery="item"
              />
            </template> -->
            <Empty v-if="!filteredDraggableDeliveries.length" :text="$t('global.emptyByFilter')" />
            <DraggableList
              v-show="filteredDraggableDeliveries.length"
              :value="filteredDraggableDeliveries"
              :disabledItem="{ field: 'delivery_event_type_id', value: [4, 5] }"
              :confirmation="dragConfirmation"
              :disabled="disabled || !!search || !hasPermission"
            >
              <template #default="item">
                <DeliveryEvent
                  ref="deliveryEvent"
                  :key="item.id"
                  :delivery="item"
                  :disabled="disabled"
                  :loadingPickup="loading.pickup"
                  :loadingRemovePickup="loading.removePickup"
                  @onRemovePickup="onRemovePickup"
                  @onAddPickup="onAddPickup"
                  @changeDeliveryDay="(eventId) => onChangeDeliveryDay(eventId)"
                  @changesHistory="(eventId) => onOpenModal(eventId, 'isChangesHistoryOpen')"
                  @cancelDelivery="(eventId) => onOpenModal(eventId, 'isCancelDeliveryOpen')"
                  @transferRx="(eventId) => onOpenModal(eventId, 'isTransferRxOpen')"
                  @changeStatus="(args) => onOpenModalWithArgs(args, 'isChangeStatus')"
                  @whereIsMyRx="(args) => onOpenModalWithArgs(args, 'isShareRxOpen')"
                  @editCopayment="handleEditCopayment"
                  @unassignDelivery="onOpenUnassignModal"
                  @editParticipant="
                    (participandDelivery) => onOpenModal(participandDelivery, 'isEditParticipant')
                  "
                  showAddress
                />
              </template>
            </DraggableList>
            <!-- <template v-for="item in deliveryEvents">
              <DeliveryEvent
                v-if="!search && item.delivery_event_type_id == 7"
                :key="item.id"
                :delivery="item"
                :disabled="disabled"
              />
            </template> -->
            <Empty v-if="!deliveryEvents.length" :title="$t('manifest.deliveryEventLess')" />
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {
  LunchBreak,
  ChangesHistory,
  CancelDelivery,
  ShareRx,
  ChangeStatus,
  TransferRx,
  UnasignDeliveryModal
} from './Modals';
import { Deliveries } from '../../views/Fragments';
import {
  Button,
  DraggableList,
  Empty,
  InputSearch,
  ManifestStatusSignUI,
  RecalculateManifestRoute,
  ModalEditParticipant
} from '@/components';
import { toast, confirm } from '@/utils/dialog';
import DeliveryEvent from './DeliveryEvent.vue';
import { MetersToMiles } from '@/utils/Metrics';
import { Permissions } from '@/utils/Secure';
import { BypassModal } from '@/views/Fragments';
import { handlerAddPickup, handlerRemovePickup } from '../../services/Pickup';
import CDPFilter from '../CDP/CDPFilter.vue';
import { isEmpty, filter, uniq } from 'ramda';
import { ModalCopayment } from '..';
import { dashboardUpdateCommit } from '../../store/commits';

export default {
  components: {
    Button,
    BypassModal,
    CancelDelivery,
    ChangesHistory,
    ChangeStatus,
    Deliveries,
    DeliveryEvent,
    DraggableList,
    Empty,
    InputSearch,
    LunchBreak,
    ManifestStatusSignUI,
    ModalEditParticipant,
    RecalculateManifestRoute,
    ShareRx,
    TransferRx,
    UnasignDeliveryModal,
    CDPFilter,
    ModalCopayment
  },
  created() {
    this.init();
  },
  destroyed() {
    this.unsubscribe();
    clearTimeout(this.manifestTimeout);
  },
  data() {
    return {
      cancelToken: null,
      currentDelivery: null,
      CDPs: [],
      CDPsFilter: [],
      deliveryEvents: [],
      deliveryEvent: null,
      draggableDeliveries: [],
      eventId: null,
      liveManifest: {},
      loading: {
        manifest: true,
        deliveryEvents: false,
        undoAssign: false,
        notifyDriver: false,
        pickup: null,
        removePickup: null
      },
      manifest: {},
      manifestId: null,
      manifestTimeout: null,
      participandDelivery: null,
      search: '',
      modals: {
        isAddOnOpen: false,
        isBypass: false,
        isCancelDeliveryOpen: false,
        isChangesHistoryOpen: false,
        isChangeStatus: false,
        isEditParticipant: false,
        isLunchBreakOpen: false,
        isShareRxOpen: false,
        isEditCopayment: false,
        isTransferRxOpen: false,
        isUnassignOpen: false
      },
      run: {},
      trackingToken: null,
      unsubscribe: null
    };
  },
  computed: {
    isToday() {
      return !this.date || this.date == this.$moment().format('YYYY-MM-DD');
    },
    isEmpty() {
      return !this.run.id;
    },
    CDPsInManifest() {
      const validId = (n) => !!n;
      return filter(validId, uniq(this.draggableDeliveries.map((DD) => DD.close_door_pharmacy_id)));
    },
    disabledBypass() {
      return this.manifest?.manifest_status_name === 'Done';
    },
    filteredDraggableDeliveries() {
      const search = this.search.toLowerCase();
      let deliveries = this.draggableDeliveries;
      const compareValue = (str, query) => {
        return String(str).toLowerCase().indexOf(query) > -1;
      };
      if (search)
        deliveries = deliveries.filter((delivery) => {
          return (
            compareValue(delivery.participant_full_name, search) ||
            compareValue(delivery.client_code, search) ||
            compareValue(delivery.destination_full_address, search) ||
            compareValue(delivery.tracking_number, search) ||
            compareValue(delivery.order_number, search)
          );
        });
      if (!isEmpty(this.CDPsFilter)) {
        deliveries = deliveries.filter((delivery) => {
          return this.CDPsFilter.find((CDPid) => delivery.close_door_pharmacy_id === CDPid);
        });
      }
      return deliveries;
    },
    distance() {
      return this.manifest ? MetersToMiles(this.manifest.estimated_distance) : 0;
    },
    workingHours() {
      return this.manifest ? this.manifest.estimated_time / 60 / 60 : 0;
    },
    Permission() {
      return Permissions.Dispatch;
    },
    PermissionBypass() {
      return Permissions.Employees;
    },
    hasPermission() {
      return this.Secure.permissionValidator(this.Permission.update);
    }
  },
  methods: {
    init() {
      this.unsubscribe = this.$store.subscribe(
        ({ type }, { map: { response }, dispatch: { run } }) => {
          const updateTypeEvent = 'dispatch/updateManifestEvents';
          if (
            type === updateTypeEvent ||
            (type === 'map/response' && response?.type === updateTypeEvent)
          ) {
            if (response?.manifestId === undefined || response?.manifestId === this.manifest.id)
              this.getManifest();
          }
          // if (type === 'dispatch/manifest') {
          //   console.log('🧬-📡', { manifest });
          // }
          if (type === 'dispatch/run') {
            this.cancelRequest();
            this.run = run;
            if (run.id) {
              this.manifest.id = run.id;
              this.getManifest(false);
            } else this.manifest = {};
          }
          if (type == 'schedule/assignedDelivery') {
            this.getManifest();
          }
        }
      );
    },

    async onRemovePickup(deliveryId) {
      this.loading.removePickup = deliveryId;
      try {
        const success = await handlerRemovePickup({ manifestId: this.manifest.id, deliveryId });

        if (success) await this.getManifest(true);
      } catch (error) {
        console.log(error);
      }
      this.loading.removePickup = null;
    },
    async onAddPickup({ delivery, secuence, position }) {
      try {
        this.loading.pickup = { id: delivery.id, position };
        const success = await handlerAddPickup({ ...delivery, secuence });
        if (success) await this.getManifest(true);
      } catch (error) {
        console.log('::::Error', error);
      }
      this.loading.pickup = null;
    },
    handleEditCopayment(delivery) {
      this.modals.isEditCopayment = true;
      this.deliveryEvent = delivery;
    },
    handleSavedCopayment(copayment_amount) {
      const delivery = this.draggableDeliveries.find(({ id }) => id === this.deliveryEvent.id);
      if (delivery) delivery.copayment_amount = copayment_amount;
    },
    onTransferRx() {
      this.$store.commit('dispatch/reloadTable');
      this.getManifest();
    },
    cancelRequest(message = 'Avoid multiple') {
      if (this.cancelToken) this.cancelToken.cancel(message);
    },
    onOpenModal(delivery, modalName) {
      if (modalName === 'isEditParticipant') this.participandDelivery = delivery;
      else this.eventId = delivery.id;
      this.modals[modalName] = true;
    },
    onOpenModalWithArgs(args, modalName) {
      this.deliveryEvent = args.delivery;
      this.eventId = args.delivery_id;
      this.trackingToken = args.delivery_tracking_token;
      this.modals[modalName] = true;
    },
    async onOpenUnassignModal(delivery) {
      this.$refs.deliveryEvent.loading.dropdown = false;
      const { id, delivery_manifest_id, order_number } = delivery;

      try {
        await this.Api.delete(`delivery_manifest/${delivery_manifest_id}/delivery_events/${id}`);
        this.$toast(
          'success',
          this.$tc('schedule.messages.unassigned', 1, [`#${order_number}`]),
          5000
        );
        this.onRemoved();
      } catch (error) {
        this.currentDelivery = delivery;
        this.modals.isUnassignOpen = true;
      }

      this.$refs.deliveryEvent.loading.dropdown = true;
    },
    onRemoved() {
      this.$store.commit('dispatch/updateManifestEvents');
      this.$store.commit('dispatch/reloadTable');
      this.$store.commit('deliveries/reloadTable');
      dashboardUpdateCommit();
    },
    isAddOnOpen() {
      this.modals.isAddOnOpen = true;
      this.manifestId = this.manifest.id;
    },
    isBypassOpen() {
      this.modals.isBypass = true;
    },
    isLunchBreak({ nextItem, prevItem, item }) {
      return (
        item?.delivery_event_type_id === 4 ||
        item?.delivery_event_type_id === 5 ||
        (nextItem?.delivery_event_type_id === 5 && prevItem?.delivery_event_type_id === 4)
      );
    },
    async dragConfirmation({ newIndex, item, nextItem, ...rest }) {
      if (
        nextItem &&
        (![1, 5, 6, 7, 8, 11, 12, 15, 16, 17].includes(item.delivery_event_status_id) ||
          [2, 3, 4, 9, 10, 13, 14, 18].includes(nextItem.delivery_event_status_id))
      ) {
        // return false; // Allow move to any secuence
      }

      if (this.isLunchBreak({ item, ...rest })) return false;
      const result = await this.updateDeliverySecuence(item.id, newIndex + 2);
      if (result) {
        await this.getManifest(true);
        toast('success', this.$t('messages.updated'));
        return true;
      } else {
        return false;
      }
    },
    setCDPs(CDPs) {
      this.CDPs = CDPs;
      this.syncEventsToCDP();
    },
    CDPsById(id) {
      return this.CDPs.find((CDP) => CDP.id === id);
    },
    async syncEventsToCDP() {
      const hasCDPs = !isEmpty(this.CDPs);
      const hasEvents = !isEmpty(this.deliveryEvents);

      if (hasCDPs && hasEvents) {
        this.deliveryEvents.map((event) => {
          if (event.close_door_pharmacy_id) {
            event.color = this.CDPsById(event.close_door_pharmacy_id)?.color;
          }
        });
      }
    },
    async getManifest(isAutoUpdate) {
      clearTimeout(this.manifestTimeout);
      if (this.manifest?.id) {
        if (!isAutoUpdate) this.loading.manifest = true;
        try {
          this.cancelToken = this.Api.cancelToken;
          const { data } = await this.Api.get(`delivery_manifest/${this.manifest.id}`, {
            cancelToken: this.cancelToken.token
          });
          this.manifest = data;
          this.deliveryEvents = data.events;
          await this.syncEventsToCDP();
          this.$store.commit('dispatch/manifest', this.manifest);
        } catch (error) {
          console.error(error);
          if (error.aborted) return;
        }
        this.loading.manifest = false;
        // this.setGetManifestTimeout();
      } else {
        this.deliveryEvents = [];
      }
    },
    // setGetManifestTimeout() {
    //   clearTimeout(this.manifestTimeout);
    //   if (this.isToday)
    //     this.manifestTimeout = setTimeout(() => {
    //       if (this.manifest?.id) this.getManifest(true);
    //     }, 60000);
    // },
    async updateDeliverySecuence(eventId, secuence, isOverride) {
      try {
        await this.Api.put(
          `delivery_manifest/${this.manifest.id}/delivery_events${
            isOverride ? '_override' : ''
          }/${eventId}`,
          {
            secuence
          }
        );
        return true;
      } catch (error) {
        if (error?.data?.status_code === 'OVERTIME_WORK') {
          return await new Promise((resolve) => {
            confirm({
              title: this.$t('confirms.continue'),
              message: this.$t('schedule.confirm.overtime'),
              cancelText: this.$t('confirm.no'),
              confirmText: this.$t('confirm.yes'),
              onCancel: () => resolve(false),
              onConfirm: () => resolve(this.updateDeliverySecuence(eventId, secuence, true))
            });
          });
        } else return false;
      }
    },
    async notificateToApp() {
      this.loading.notifyDriver = true;
      try {
        await this.Api.post(`/delivery_manifest/${this.manifest.id}/notify_driver`);
        this.$toast('success', this.$t('dispatch.notificationSent'));
      } catch (error) {
        console.log(error);
      }
      this.loading.notifyDriver = false;
    }
  },
  watch: {
    deliveryEvents(events) {
      this.draggableDeliveries = events.filter((e) => {
        const type = e.delivery_event_type_id;
        const mandatory = e.event_type_is_mandatory;
        return (type >= 2 && type <= 5) || (type === 8 && mandatory === 0);
      });
    }
    /* 'manifest.id'(value) {
      if (value) this.cancelRequest();
    } */
  },
  props: {
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: true },
    date: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
#manifest
  transition: $transition-dark-mode
  width: 370px
  height: 100%
  max-height: calc(100vh - 130px)
  display: flex
  flex-flow: column
  border-radius: $br-md
  .f-sm
    font-size: $f-sm
  .loadingManifests
    height: 100%
    .b-skeleton
      height: 100%
  &.is-empty
    padding: 0
  #manifest-header
    margin: 0
  .title-container
    margin-bottom: 10px
    .title
      font-size: $f-xl
      font-weight: bold
      // height: 30px
      margin-bottom: 5px !important
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    .text
      font-size: $f-xs
    .help.is-danger
      margin: 0
  .button-manifest
    // min-width: 85%
    margin: 0 0 0.5rem
    @media only screen and (max-width: $bp-lg)
      margin-bottom: 0.25rem
  #manifest-container
    height: calc(100% - 210px)
    border: 1px solid $gray-50
    border-radius: $br-sm
    .draggable-list
      height: 100%
      .draggable-item:last-child
        .delivery-event
          margin: 0
    &>.delivery-event
      &:last-child
        margin-bottom: 0
    #manifest-loading
      height: 100%
      .b-skeleton
        height: 100%
</style>
<style lang="sass">
.dark
  #manifest-title
    color: $gray-50
  #manifest
    .f-sm
      color: $gray-50
</style>
