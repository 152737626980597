<template>
  <Card>
    <div id="delivery-issues-table">
      <div class="header-card flex f-ai f-jsb mb-4">
        <div class="flex f-ai">
          <div class="ml-4 mr-4">
            <StepCardHeader />
          </div>
          <div class="symbology">
            <DeliveryStatusUI type="rush" component="simple-text" />
            <DeliveryStatusUI type="priority" component="simple-text" />
            <DeliveryStatusUI type="refrigerated" component="simple-text" />
            <DeliveryStatusUI type="leave-at-door" component="simple-text" />
            <DeliveryStatusUI type="collect" component="simple-text" />
            <DeliveryStatusUI type="male-driver" component="simple-text" />
            <DeliveryStatusUI type="female-driver" component="simple-text" />
          </div>
        </div>
        <DatePicker
          class="datepicker"
          v-model="params.date"
          @input="updateUrlQuery"
          :maxDate="maxDate"
          icon-right="calendar-today"
          :disabled="loading"
          position="is-bottom-left"
        />
      </div>
      <MainTable ref="Table" :loading.sync="loading" :params="params" />
    </div>
  </Card>
</template>

<script>
import { Card, DatePicker, DeliveryStatusUI } from '@/components';
import MainTable from './MainTable.vue';
import StepCardHeader from '@/components/StepCardHeader.vue';

export default {
  components: {
    Card,
    DatePicker,
    StepCardHeader,
    MainTable,
    DeliveryStatusUI
  },
  data() {
    const date = this.$route.query.date;
    return {
      loading: false,
      params: { date: date || this.$moment().format('YYYY-MM-DD') }
    };
  },
  methods: {
    updateUrlQuery() {
      this.$router.replace({ query: { date: this.params.date } });
    }
  },
  computed: {
    date() {
      return this.$route.query.date;
    },
    maxDate() {
      return this.$moment().toDate();
    }
  },
  watch: {
    date(date) {
      this.params.date = date;
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: calc(100% - 100px)
  #delivery-issues-table
    width: 100%
    margin-top: 5px
    margin-right: 15px
    .input-search
      width: 40%
  .header-card
    margin-top: 20px
    margin-left: -15px
  .symbology
    display: flex
    ::v-deep .simple-delivery
      margin-left: 1rem
      .icon
        margin: 0 !important
</style>

<style lang="sass">
.dark
  .card
    .symbology
      p
        color: $main-background
</style>
